import { variation } from 'ember-launch-darkly';

import { ORGA_STATUS } from 'qonto/constants/organization';

import ApplicationAbility from './application';

export default class CategoriesAbility extends ApplicationAbility {
  get canAccess() {
    return (
      this.featuresManager.isEnabled('displayCashflowV1') &&
      variation('feature--boolean-cash-flow-categories-transactions') &&
      this.organization.status !== ORGA_STATUS.DEACTIVATED
    );
  }

  get canView() {
    return this.canAccess && this.permissions.categories.read;
  }

  get canAssign() {
    return this.canAccess && this.permissions.categories.assign;
  }
}
