import { service } from '@ember/service';

import ApplicationAbility from './application';

export default class InvitesAbility extends ApplicationAbility {
  @service organizationManager;

  get canCreate() {
    return this.permissions.invites.create;
  }

  get canViewManagerSplitPermission() {
    let { organization } = this.organizationManager;
    return organization.hasModularPricing;
  }
}
