import { decamelize } from '@ember/string';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import BillerAdapter from 'qonto/adapters/base/biller';
import { billerV4Namespace } from 'qonto/constants/hosts';

export default class OrganizationSubscriptionNewAdapter extends BillerAdapter {
  namespace = billerV4Namespace;
  headerOverrides = {};

  pathForType() {
    return 'subscriptions';
  }

  get headers() {
    return { ...this.networkManager.requestHeaders, ...this.headerOverrides };
  }

  query(store, type, query, snapshot, { adapterOptions } = {}) {
    if (adapterOptions?.headers) {
      this.headerOverrides = adapterOptions.headers;
    }
    let promise = super.query(...arguments);
    this.headerOverrides = {};
    return promise;
  }

  handleResponse(status, headers, payload) {
    if (status === 422 && 'total_estimate' in payload) {
      return { payload, status };
    }

    return super.handleResponse(...arguments);
  }

  @waitFor
  async upgradeRecommendation(featureCode) {
    return await adapterAction(this, 'subscriptions', {
      method: 'GET',
      path: `upgrade_recommendation?feature_code=${decamelize(featureCode)}`,
    });
  }
}
