import { service } from '@ember/service';

import ApplicationAbility from './application';

export default class ApprovalWorkflowAbility extends ApplicationAbility {
  @service organizationManager;

  get canSeeState() {
    return (
      Boolean(this.pricePlanFeatures.approvalWorkflows) &&
      this.featuresManager.isEnabled('approvalWorkflows')
    );
  }

  get canRead() {
    return (
      Boolean(this.pricePlanFeatures.approvalWorkflows) &&
      this.featuresManager.isEnabled('approvalWorkflows') &&
      this.permissions.approval_workflows.read
    );
  }

  get canCreate() {
    return (
      Boolean(this.pricePlanFeatures.approvalWorkflows) &&
      this.featuresManager.isEnabled('approvalWorkflows') &&
      this.permissions.approval_workflows.create
    );
  }

  get canUpdate() {
    return (
      Boolean(this.pricePlanFeatures.approvalWorkflows) &&
      this.featuresManager.isEnabled('approvalWorkflows') &&
      this.permissions.approval_workflows.edit
    );
  }

  get canUpsell() {
    // TODO modularPricing cleanup
    if (!this.organizationManager.organization.hasModularPricing) {
      return false;
    }

    return !this.pricePlanFeatures.approvalWorkflows;
  }
}
