import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { ENABLED_LEGAL_COUNTRIES } from 'qonto/constants/insurance-hub';

import ApplicationAbility from './application';

export default class InsuranceAbility extends ApplicationAbility {
  @service organizationManager;

  get canRead() {
    return (
      this.permissions.insurance_contracts.read &&
      ENABLED_LEGAL_COUNTRIES.includes(this.organizationManager.organization.legalCountry)
    );
  }

  get canNavigate() {
    return variation('feature--boolean-insurance-hub-beta') && this.canRead;
  }
}
