import ApplicationAbility from './application';

export default class ClientHubAbility extends ApplicationAbility {
  get canWrite() {
    return this.permissions.clients.write;
  }

  get canRead() {
    let {
      organization: { legalCountry },
    } = this.organizationManager;

    return legalCountry !== 'PT' && this.permissions.clients.read;
  }
}
