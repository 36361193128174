import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApplicationAdapter from 'qonto/adapters/application';
import { pricingCatalogBaseURL, pricingCatalogNamespace } from 'qonto/constants/hosts';

export default class SubscriptionProductAdapter extends ApplicationAdapter {
  host = pricingCatalogBaseURL;
  namespace = pricingCatalogNamespace;

  @service organizationManager;

  shouldReloadRecord() {
    return false;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }

  shouldReloadAll() {
    return true;
  }

  pathForType() {
    return 'products';
  }

  buildQuery() {
    let query = super.buildQuery();
    query.legal_country = this.organizationManager.organization.legalCountry;
    return query;
  }

  query(store, type, query) {
    query.legal_country = this.organizationManager.organization.legalCountry;
    return super.query(...arguments);
  }

  @waitFor
  async getAllProducts(productType) {
    let response = await adapterAction(this, 'subscription-product', {
      method: 'GET',
      data: {
        type: productType,
        legal_country: this.organizationManager.organization.legalCountry,
      },
    });
    return response;
  }
}
