import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class CurrencyAbility extends ApplicationAbility {
  @service organizationManager;

  /**
   * Returns whether the euro currency can be used as a target currency to perform outgoing international transfers.
   *
   * This ability on its own does not guarantee user's eligibility to the outgoing international transfers feature.
   * It should always be combined with the eligibility status regarding the feature.
   *
   * @todo To remove once the feature is released and enabled for all markets.
   *
   * @returns {boolean}
   */
  get canUseInternationalEuroTarget() {
    return (
      variation('feature--boolean-international-out-eur-to-eur') &&
      this.organizationManager.organization.legalCountry !== 'IT'
    );
  }
}
